<template>
  <th
    class="main-table-th"
    :class="[
      {
        'text-center': isTextCentered,
        fixed: th.fixed,
        'has-max-selected': maxSelected !== undefined,
      },
      th.key,
    ]"
    :style="{
      'text-align': th.textAlign,
    }"
    @click="$emit(action, th)">
    <span
      v-if="th.title && !th.showIconOnly"
      :class="{
        pointer: th.selectable || th.orderable,
      }">
      {{ th.title }}
    </span>
    <template v-else-if="th.key === 'selectColumn'">
      <MdtCheckbox
        v-if="maxSelected === undefined"
        v-model="selectAll" />
      <span v-else-if="maxSelected !== 1">
        <div class="btn btn-basic btn-size-32 no-hover">
          {{ selectedRows.length }} / {{ maxSelected }}
        </div>
      </span>
    </template>
    <i
      v-else-if="th.icon && th.showIconOnly"
      :class="`${th.icon} ${th.orderable ? 'pointer' : ''}`" />
    <i
      v-if="sort && sort.key === th.key"
      :class="`fas fa-caret-${sort.order === 'asc' ? 'down' : 'up'}`"
      class="sort-icon" />
  </th>
</template>

<script>
export default {
  name: 'TableTh',
  props: {
    th: {
      type: Object,
      required: true,
    },
    sort: {
      type: Object,
      default: () => ({}),
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
    maxSelected: {
      type: Number,
      default: undefined,
    },
    tableBody: {
      type: Array,
      default: () => [],
    },
    textCenteredColumns: {
      type: Array,
      required: true,
    },
  },
  data() {
    let action = '';
    if (this.th.selectable) action = 'select';
    else if (this.th.orderable) action = 'sort';
    else if (this.th.favoritable) action = 'favoritize';

    return {
      action,
    };
  },
  computed: {
    selectAll: {
      get() {
        // filter only through non-readonly rows
        // eslint-disable-next-line no-underscore-dangle
        const tBody = this.tableBody.filter((tb) => !tb._readonly?.value);
        const tSelected = this.selectedRows;
        if (!tSelected.length) return false;
        return tBody.every((tb) => {
          const selectedIndex = tSelected
            // eslint-disable-next-line no-underscore-dangle
            .findIndex((selected) => (selected._id === tb._id) && !tb._readonly?.value);
          return selectedIndex > -1;
        });
      },
      set(checked) {
        // filter only through non-readonly rows
        // eslint-disable-next-line no-underscore-dangle
        const tBody = this.tableBody.filter((tb) => !tb._readonly?.value);
        const tSelected = this.selectedRows;
        tBody.forEach((tb) => {
          const selectedIndex = tSelected
            // eslint-disable-next-line no-underscore-dangle
            .findIndex((selected) => (selected._id === tb._id) && !tb._readonly?.value);

          if (checked && selectedIndex === -1) {
            this.selectedRows.push(tb); // eslint-disable-line vue/no-mutating-props
          } else if (!checked && selectedIndex > -1) {
            this.selectedRows.splice(selectedIndex, 1); // eslint-disable-line vue/no-mutating-props
          }
        });
      },
    },
    isTextCentered() {
      return this.th.icon || this.textCenteredColumns.includes(this.th.key);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-table-th {
  position: relative;

  ::v-deep {
    .mdt-checkbox label:before,
    .mdt-checkbox label:after {
      border-color: $color-text-secondary;
    }
  }
}

.sort-icon {
  padding-left: 8px;
}
</style>
