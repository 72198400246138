<template>
  <div class="table-td-button-select-b2b">
    <MdtButtonSelect
      :items="itemsTransformed"
      size="size-32"
      relative
      @buttonSelect="buttonSelect" />
  </div>
</template>

<script>
export default {
  name: 'TableTdButtonSelectB2B',
  props: {
    items: {
      type: Array,
      required: true,
    },
    tableRow: {
      type: Object,
      required: true,
    },
  },
  data() {
    const { translate } = this.$options.filters;
    return {
      translate,
    };
  },
  computed: {
    project() {
      return this.$store.state.projects.project;
    },
    itemsTransformed() {
      const tempItems = JSON.parse(JSON.stringify(this.items));

      tempItems.forEach((item) => {
        switch (item.view.name) {
          case 'uploadNBO':
          case 'uploadBO':
          case 'notInterested':
          case 'resetNotInterested':
          case 'interested':
          case 'signNDAManually':
          case 'directAssignment': {
            let disabled = false;
            let tooltip = '';
            // Disable if investor is inactive
            if (this.$route.name === 'ProjectDetails') {
              const isProjectSold = this.project.progress_state === 'sold';
              const isInvestorInactive = this.tableRow._investor_state === 'investor.inactive'; // eslint-disable-line no-underscore-dangle
              disabled = isProjectSold || isInvestorInactive;
              tooltip = disabled
                ? isProjectSold
                  ? this.translate('general_project_sold')
                  : this.translate('general_investor_inactive')
                : '';
            // Disable if project is sold
            } else { // else if this.$route.name === 'InvestorDetails'
              disabled = this.tableRow._project_state === 'sold'; // eslint-disable-line no-underscore-dangle
              tooltip = disabled ? this.translate('general_project_sold') : '';
            }
            item.tooltip = tooltip;
            item.disabled = disabled;
            break;
          }
          case 'sendPropose': {
            let disabled = false;
            /* eslint-disable no-underscore-dangle */
            const isInvestorInactive = this.tableRow._detail_column?.disabled_row?.disabled;
            const isProjectPublished = this.project._is_published;
            // Disable if project is not pusblished or investor is inactive
            disabled = (this.$route.name === 'ProjectDetails')
              ? (isInvestorInactive || !isProjectPublished)
              : (!this.tableRow._is_published);
            item.tooltip = disabled
              ? isInvestorInactive
                ? this.translate('general_investor_inactive')
                : this.translate('general_project_not_published')
              : '';
            item.disabled = disabled;
            /* eslint-enable no-underscore-dangle */
            break;
          }
          default: break;
        }
      });

      return tempItems;
    },
  },
  methods: {
    buttonSelect(selectedItem) {
      this.$emit('tableTdButtonSelect', {
        buttonName: selectedItem.view.name,
        tableRow: this.tableRow,
      });
    },
  },
};
</script>
