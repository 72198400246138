<template>
  <div
    class="mdt-message flex-center-v"
    :class="`mdt-message-${type}`">
    <i
      class="fas mdt-message-icon"
      :class="icon[type]" />
    <div class="mdt-message-text-wrapper">
      <div
        v-if="msg || title"
        class="mdt-message-title"
        v-html="sanitizedContent(msg || title)" />
      <slot name="multi-messages" />
      <div
        v-if="body"
        class="mdt-message-body"
        v-html="sanitizedContent(body)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MdtMessage',
  props: {
    msg: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'info',
      validator: (value) => {
        const match = ['info', 'success', 'warning', 'danger'];
        return match.includes(value);
      },
    },
  },
  data() {
    return {
      icon: {
        info: 'fa-check-circle',
        success: 'fa-check-circle',
        warning: 'fa-info-circle',
        danger: 'fa-info-circle',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
$message-color-info: $color-info;
$message-color-danger: $color-danger;

.mdt-message {
  padding: 12px;
  color: $color-text-primary;
  background-color: $color-info-light;
  border: 1px solid $color-info;
  border-radius: 8px;

  .mdt-message-icon {
    align-self: flex-start;
    color: $color-info;
    font-size: 16px;
  }

  .mdt-message-text-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 10px;

    .mdt-message-title {
      font-weight: $font-weight-bold;
      font-size: 14px;
    }

    .mdt-message-body {
      padding-top: 5px;
      font-size: 13px;
    }
  }

  &.mdt-message-success {
    background-color: $color-success-light;
    border-color: $color-success;
    .mdt-message-icon { color: $color-success; }
  }

  &.mdt-message-warning {
    background-color: $color-warning-light;
    border-color: $color-warning;
    .mdt-message-icon { color: $color-warning; }
  }

  &.mdt-message-danger {
    background-color: $color-danger-light;
    border-color: $color-danger;
    .mdt-message-icon { color: $color-danger; }
  }
}
</style>
