<template>
  <MdtModal
    class="mdt-modal-privacy-policy"
    :title="'admin_privacy_policy' | translate"
    type="info"
    @close="$emit('close')"
    @cancel="$emit('close')">
    <MdtLoader v-if="loading" />
    <div
      v-else
      v-html="sanitizedContent(privacyPolicy)" />
  </MdtModal>
</template>

<script>
export default {
  name: 'MdtModalPrivacyPolicy',
  props: {
    datenschutz: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      privacyPolicy: null,
      loading: true,
    };
  },
  mounted() {
    if (this.datenschutz) {
      this.privacyPolicy = this.datenschutz;
      this.loading = false;
      return;
    }
    this.$store.dispatch('getPrivacyPolicy').then((data) => {
      this.privacyPolicy = data.contentText;
    }).catch(this.showResponseError)
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.mdt-modal-privacy-policy {
  font-size: 1rem;
}

::v-deep .modal-body {
  width: 75vw;
  max-width: 1250px;

  a {
    color: $color-theme;
  }
}
</style>
