<template>
  <div
    class="mdt-modal mdt-modal-component flex-center"
    @wheel.stop
    @touchmove.stop
    @scroll.stop>
    <div
      class="mdt-modal-wrapper"
      :style="{ width }"
      :class="{ 'hide-footer-border': hideFooterBorder }">
      <div
        ref="modalHeader"
        :style="{ height: headerHeight }"
        class="modal-header flex-center-h"
        :class="{ 'close-hidden': hideClose }">
        <span
          class="header-title"
          :class="{ 'lsz-header': formTemplate === FORM_TEMPLATE.CITY_OF_ZURICH }">
          {{ title }}
        </span>
        <span
          v-if="subtitle"
          class="header-subtitle">
          {{ subtitle }}
        </span>
        <div v-if="!hideClose">
          <i
            v-if="formTemplate !== FORM_TEMPLATE.CITY_OF_ZURICH"
            class="fas fa-times icon-close"
            @click.stop="onClose" />
          <StzhButtonComponent
            v-else
            value="close"
            icon-only
            variant="tertiary"
            icon="close"
            size="tiny"
            class="icon-close"
            @stzhClick="onClose" />
        </div>
      </div>
      <div class="modal-body">
        <slot name="body-header" />
        <div
          v-if="hideVueScroll"
          class="body-content"
          :style="bodyContentStyle">
          <div class="body-content-wrapper">
            <slot />
          </div>
        </div>
        <vue-scroll
          v-else
          class="body-content-vuescroll">
          <div
            class="body-content"
            :style="bodyContentStyle">
            <div class="body-content-wrapper">
              <slot />
            </div>
          </div>
        </vue-scroll>
      </div>
      <div
        class="modal-footer"
        :style="{ height: footerHeight }"
        :class="{ 'space-between': spaceBetweenFooterButtons }">
        <div
          v-if="type !== 'custom'"
          class="btn btn-light button-cancel"
          @click.stop="onCancel">
          {{ 'general_cancel' | translate }}
        </div>
        <slot name="button" />
        <div
          v-if="type === 'success'"
          v-tooltip="buttonTooltip"
          class="btn btn-primary button-success"
          :class="{ 'btn-disabled': buttonDisabled || isDataChanged === false }"
          @click.stop="buttonDisabled || isDataChanged === false ? null : $emit('save')">
          {{ buttonText || 'general_save' | translate }}
        </div>
        <div
          v-if="type === 'danger'"
          v-tooltip="buttonTooltip"
          class="btn btn-danger button-danger"
          :class="{ 'btn-disabled': buttonDisabled || isDataChanged === false }"
          @click.stop="buttonDisabled || isDataChanged === false ? null : $emit('delete')">
          {{ buttonText || 'general_delete' | translate }}
        </div>
      </div>
    </div>
    <MdtModalConfirmation
      v-if="modalKey === 'close-confirmation'"
      :title="'admin_discard_changes' | translate"
      :btn-text="'admin_close_and_discard' | translate"
      type="discard-changes"
      class="discard-changes"
      @close="modalKey = ''"
      @confirm="$emit('close')" />
  </div>
</template>

<script>
import variables from '@/scss/abstract/_variablesExport.module.scss';
import { helpers } from '@/utility';
import { FORM_TEMPLATE } from '@/utility/constants';
import StzhButtonComponent
  from '@/components/application-form/stzh-components/StzhButtonComponent.vue';

export default {
  name: 'MdtModal',
  components: { StzhButtonComponent },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'success',
      validator(value) {
        return ['info', 'success', 'danger', 'custom'].includes(value);
      },
    },
    isDataChanged: {
      type: Boolean,
      default: undefined,
    },
    spaceBetweenFooterButtons: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    minHeight: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: String,
      default: '90vh',
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonTooltip: {
      type: String,
      default: '',
    },
    hideVueScroll: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    headerHeight: {
      type: String,
      default: variables.modalHeaderHeight,
    },
    footerHeight: {
      type: String,
      default: variables.modalFooterHeight,
    },
    hideFooterBorder: {
      type: Boolean,
      default: false,
    },
    formTemplate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modalHeaderHeight: variables.modalHeaderHeight,
      bodyHeaderSlotHeight: 0,
      modalKey: '',
      FORM_TEMPLATE,
    };
  },
  computed: {
    bodyContentStyle() {
      const diffHeight = `${this.modalHeaderHeight} - ${variables.modalFooterHeight} - ${this.bodyHeaderSlotHeight}`;
      return {
        height: this.height
          ? `calc(${this.height} - ${diffHeight})` : null,
        minHeight: this.minHeight
          ? `calc(${this.minHeight} - ${diffHeight})` : null,
        maxHeight: `calc(${this.maxHeight} - ${diffHeight})`,
      };
    },
  },
  mounted() {
    // handle scrollbars on modal open
    helpers.handleScrollbarsOnModalOpen();
    // set modal header height
    this.setModalHeaderHeight();
    // set body header slot height
    this.setBodyHeaderSlotHeight();
  },
  destroyed() {
    // handle scrollbars on modal close
    helpers.handleScrollbarsOnModalClose();
  },
  methods: {
    setModalHeaderHeight() {
      const { modalHeader } = this.$refs;
      this.modalHeaderHeight = `${modalHeader.offsetHeight}px`;
    },
    setBodyHeaderSlotHeight() {
      const bodyHeaderSlot = this.$slots['body-header'];
      const bodyHeaderSlotHeight = bodyHeaderSlot?.[0]?.elm?.offsetHeight || 0;
      this.bodyHeaderSlotHeight = `${bodyHeaderSlotHeight}px`;
    },
    onClose() {
      if (this.isDataChanged) this.modalKey = 'close-confirmation';
      else this.$emit('close');
    },
    onCancel() {
      if (this.isDataChanged) this.modalKey = 'close-confirmation';
      else this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  color: $color-text-primary;
  background-color: rgba($color-text-primary-rgb, 0.7);
  z-index: 1002;

  &.no-max-width {
    .mdt-modal-wrapper {
      max-width: 99vw !important;
    }
  }

  &.mdt-modal-confirmation {
    .mdt-modal-wrapper {
      max-width: 420px;
    }

    .modal-header {
      height: $modal-confirmation-header-height;
    }

    .modal-body {
      .body-content {
        .body-content-wrapper {
          // remove padding to content wrapper on confirmation modals
          padding-bottom: 0;
        }
      }
    }

    .modal-footer {
      box-shadow: none;
    }
  }

  .mdt-modal-wrapper {
    max-width: 99vw;
    background-color: $color-back-primary;
    box-shadow: 0 3px 30px #00000040;
    border-radius: 8px;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 30px;
    border-radius: 4px 4px 0 0;
    flex-shrink: 0;

    .header-title {
      padding-right: 53px;
      color: $color-text-primary;
      font-size: 24px;
      font-weight: $font-weight-bold;
    }

    .lsz-header {
      margin-left: 22px;
    }

    .header-subtitle {
      padding: 3px 53px 0 0;
      color: $color-text-secondary;
      font-size: 16px;
      font-weight: $font-weight-normal;
    }

    .icon-close {
      position: absolute;
      top: 21px;
      right: 21px;
      color: $color-text-secondary;
      font-size: 16px;

      &.disabled {
        opacity: 0.3;
      }
    }

    &.close-hidden {
      .header-title,
      .header-subtitle {
        padding-right: 0;
      }
    }
  }

  .modal-body {
    padding: 0 30px;

    .body-content {
      .body-content-wrapper {
        // modal-body content bottom padding = 0 which keeps vuescroll to be shown all until footer
        // we add padding to content wrapper so the content will have space between footer
        padding-bottom: 30px;
      }
    }
  }

  .modal-footer {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: $modal-footer-height;
    padding: 0 30px;
    background-color: $color-back-primary;
    box-shadow: 0px 0px 20px 0px #0000001A;
    border-radius: 8px;

    &.space-between {
      justify-content: space-between;

      ::v-deep .btn:first-child {
        margin-left: 0;
      }
    }

    .btn {
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }

    ::v-deep .btn {
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .mdt-modal-wrapper.hide-footer-border {
    .body-content .body-content-wrapper {
      padding-bottom: 0;
    }

    .modal-footer {
      box-shadow: none;
    }
  }
}
</style>
