var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MdtModal',{staticClass:"mdt-modal-confirmation mdt-modal-component",class:{ 'no-max-width': _vm.noMaxWidth },attrs:{"title":_vm.title,"type":"info","hide-vue-scroll":_vm.hideVueScroll,"hide-close":""},on:{"cancel":function($event){return _vm.$emit('close')},"confirm":function($event){return _vm.$emit('confirm')}}},[_c('div',{staticClass:"modal-body-confirmation"},[(_vm.showIcon)?_c('div',{staticClass:"icon-confirmation flex-center"},[_c('i',{staticClass:"far",class:{
          'fa-info-circle icon-info': _vm.type === 'info',
          'fa-check-circle icon-success': _vm.type === 'success',
          'fa-exclamation-circle icon-danger': _vm.type === 'danger',
        }})]):_vm._e(),(_vm.infoMsg)?_c('div',{staticClass:"info-message",domProps:{"innerHTML":_vm._s(_vm.sanitizedContent(_vm.infoMsg))}}):_vm._e(),(_vm.type === 'discard-changes')?_c('div',{staticClass:"info-message discard-info-message"},[_vm._v(" "+_vm._s(_vm._f("translate")('admin_discard_changes_info_message'))+" "),_c('div',{staticClass:"question font-bold"},[_vm._v(" "+_vm._s(_vm._f("translate")('admin_are_you_sure_you_want_to_continue'))+" ")])]):_vm._e(),_vm._t("default")],2),(_vm.type !== 'info')?_c('div',{staticClass:"btn confirm-button",class:{
      'btn-danger': _vm.type === 'danger' || _vm.type === 'discard-changes',
      'btn-primary': _vm.type === 'success',
      'btn-disabled': _vm.buttonDisabled,
    },attrs:{"slot":"button"},on:{"click":function($event){_vm.buttonDisabled ? null : _vm.$emit('confirm')}},slot:"button"},[_vm._v(" "+_vm._s(_vm._f("translate")(_vm.btnText || 'general_delete'))+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }