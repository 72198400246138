<template>
  <MdtModal
    :title="title"
    type="info"
    class="mdt-modal-confirmation mdt-modal-component"
    :class="{ 'no-max-width': noMaxWidth }"
    :hide-vue-scroll="hideVueScroll"
    hide-close
    @cancel="$emit('close')"
    @confirm="$emit('confirm')">
    <div class="modal-body-confirmation">
      <div
        v-if="showIcon"
        class="icon-confirmation flex-center">
        <i
          class="far"
          :class="{
            'fa-info-circle icon-info': type === 'info',
            'fa-check-circle icon-success': type === 'success',
            'fa-exclamation-circle icon-danger': type === 'danger',
          }" />
      </div>
      <div
        v-if="infoMsg"
        class="info-message"
        v-html="sanitizedContent(infoMsg)" />
      <div
        v-if="type === 'discard-changes'"
        class="info-message discard-info-message">
        {{ 'admin_discard_changes_info_message' | translate }}
        <div class="question font-bold">
          {{ 'admin_are_you_sure_you_want_to_continue' | translate }}
        </div>
      </div>
      <slot />
    </div>
    <div
      v-if="type !== 'info'"
      slot="button"
      class="btn confirm-button"
      :class="{
        'btn-danger': type === 'danger' || type === 'discard-changes',
        'btn-primary': type === 'success',
        'btn-disabled': buttonDisabled,
      }"
      @click="buttonDisabled ? null : $emit('confirm')">
      {{ btnText || 'general_delete' | translate }}
    </div>
  </MdtModal>
</template>

<script>
import { helpers } from '@/utility';

export default {
  name: 'MdtModalConfirmation',
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'info',
      validator: (type) => ['info', 'success', 'danger', 'discard-changes'].includes(type),
    },
    infoMsg: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: '',
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    noMaxWidth: {
      type: Boolean,
      default: false,
    },
    hideVueScroll: {
      type: Boolean,
      default: false,
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // handle scrollbars on modal open
    helpers.handleScrollbarsOnModalOpen();
  },
  destroyed() {
    // handle scrollbars on modal close
    helpers.handleScrollbarsOnModalClose();
  },
};
</script>

<style lang="scss" scoped>
.modal-body-confirmation {
  word-break: break-word;

  .icon-confirmation {
    padding-bottom: 20px;

    i {
      color: $color-text-secondary;
      font-size: 2.5rem;

      &.icon-success { color: $color-success; }
      &.icon-danger { color: $color-danger; }
    }
  }

  .info-message {
    font-size: 16px;
    line-height: 20px;

    .question {
      padding-top: 20px;
    }

    // Only set margin when slot is used
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
</style>
