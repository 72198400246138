import { render, staticRenderFns } from "./MdtRadio.vue?vue&type=template&id=4c141d3e&scoped=true"
import script from "./MdtRadio.vue?vue&type=script&lang=js"
export * from "./MdtRadio.vue?vue&type=script&lang=js"
import style0 from "./MdtRadio.vue?vue&type=style&index=0&id=4c141d3e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c141d3e",
  null
  
)

export default component.exports