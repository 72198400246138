import Vue from 'vue';
import { AXIOS_RESPONSE_TIMEOUTED } from '@/utility/constants';
import helpers from '@/utility/helpers';

function handleResponseTimedout(err) {
  if (err.code === AXIOS_RESPONSE_TIMEOUTED) {
    Vue.notify({
      type: 'danger',
      text: Vue.filter('translate')('admin_request_timedout'),
    });
  }
}
let baseBackendUrl;
export default {
  request: (request) => {
    const user = JSON.parse(localStorage.getItem('mdt-user'));
    if (user?.token) {
      request.headers.Authorization = `Bearer ${user.token}`;
    }

    // Set url subdomain as Tenant-Name header, if on localhost then set 'rent'
    request.headers['Tenant-Name'] = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : 'rent';

    // Append language prefix if language !== 'de' && if not includes static/media request
    const language = JSON.parse(localStorage.getItem('mdt-language'));
    if (language && language !== 'de' && (!request.url.includes('static/') && !request.url.includes('media/') && !request.url.startsWith('http'))) {
      request.url = request.url.charAt(0) === '/'
        ? `${language}${request.url}`
        : `${language}/${request.url}`;
    }
    // On logout cancel pending request to getUnreadMessages
    if (request.url.includes('logout')) {
      Vue.axiosRequest.cancel('getUnreadMessages');
    }

    const currentOrigin = helpers.handleDoubleTopLevelDomain(Vue.prototype.$config.baseApiUrl);
    const baseBackendUrlOrigin = baseBackendUrl ? new URL(baseBackendUrl).origin : '';
    const requestURLOrigin = new URL(request.url, currentOrigin).origin;
    const allowedCORS = [currentOrigin, baseBackendUrlOrigin];
    if (!allowedCORS.includes(requestURLOrigin)) {
      // Remove the Authorization header
      delete request.headers.Authorization;
    }
    return request;
  },
  requestError: (err) => Promise.reject(err),
  response: (response) => {
    // catch init response and set baseBackendUrl for future requests
    if (response.config.url.includes('/init')) {
      baseBackendUrl = response.data?.bootstrap?.settings?.baseBackendUrl;
    }
    return response;
  },
  responseError: (err) => {
    // display notify message if request timed out
    handleResponseTimedout(err);

    const { response } = err;

    // Need unique data.message to check "Expired token"
    if (response && response.status === 401) {
      localStorage.removeItem('mdt-user');

      if (Vue.router.history.current.meta.b2b) {
        const b2bEntry = `/b2b-entry/${Vue.router.history.current.params.projectSlug}`;
        if (window.location.pathname !== b2bEntry) {
          window.location.href = b2bEntry;
        }
      } else if (Vue.router.history.current.meta.dms) {
        const dmsLogin = `/dms-login/${Vue.router.history.current.params.dmsSlug}`;
        if (window.location.pathname !== dmsLogin) {
          window.location.href = dmsLogin;
        }
      } else if (window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
    }

    // If returns 403 show notify with no-permission-message
    if (response && response.status === 403) {
      // do not display for dms-registration && dms-login view
      // dms-registration - isUserRegistered API have own notify message for this case
      // dms-login - login API have own notify message for this case
      const { href } = window.location;
      const shouldBeDisplayed = !href.includes('dms-registration') && !href.includes('dms-login');
      if (shouldBeDisplayed) {
        Vue.notify({
          type: 'danger',
          title: Vue.filter('translate')('admin_permissions'),
          text: Vue.filter('translate')('admin_access_denied'),
        });
      }
    }

    return Promise.reject(err);
  },
};
