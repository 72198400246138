import axios from 'axios';
import token from '@/api/interceptors/token';

const config = process.env.NODE_ENV === 'production' ? '/config.json' : '/config.local.json';
const apiServiceAsync = async () => {
  try {
    const { data } = await axios.get(config);
    let { baseApiUrl } = data;

    /* TODO:
    * if everything goes well on LSZ environment for stzh-upload component
    * then refactor if-else statement with commented helper on L:15
    * do not forgot to import helper
    *  */
    // baseApiUrl = helpers.handleDoubleTopLevelDomain(baseApiUrl);
    // Update base api url with project type domain
    if (baseApiUrl.includes('[project_type_domain]')) {
      const domain = window.location.hostname.split('.').slice(-1)[0];
      baseApiUrl = baseApiUrl.replace('[project_type_domain]', domain);
    } else if (baseApiUrl.includes('[double_top_level_domain]')) {
      const doubleDomain = window.location.hostname.split('.').slice(-2).join('.');
      baseApiUrl = baseApiUrl.replace('[double_top_level_domain]', doubleDomain);
    }

    const apiService = axios.create({
      baseURL: baseApiUrl,
      timeout: data.axiosTimeout || 120000,
    });

    apiService.interceptors.request.use(token.request, token.requestError);
    apiService.interceptors.response.use(token.response, token.responseError);

    return apiService;
  } catch (error) {
    console.error('Error fetching config:', error);
    throw error;
  }
};

export default apiServiceAsync;
