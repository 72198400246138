<template>
  <div
    class="unprotected-view"
    :style="{ backgroundColor: viewBackground }">
    <MdtNotify />
    <MdtLightbox />
    <UnprotectedViewHeader v-if="showHeader" />
    <div
      class="unprotected-view-content"
      :class="bodyClass">
      <RouterView class="unprotected-view-router" />
    </div>
    <UnprotectedViewFooter
      v-if="showFooter"
      :powered-by="poweredBy" />
  </div>
</template>

<script>
import UnprotectedViewHeader from '@/components/unprotected-view/UnprotectedViewHeader.vue';
import UnprotectedViewFooter from '@/components/unprotected-view/UnprotectedViewFooter.vue';
import MdtLightbox from '@/components/shared/MdtLightbox.vue';
import { APPLICATION_FORM, ROUTES_SUPPORTED_BY_LSZ } from '@/utility/constants';

export default {
  name: 'UnprotectedView',
  components: {
    UnprotectedViewHeader,
    UnprotectedViewFooter,
    MdtLightbox,
  },
  computed: {
    showHeader() {
      return this.$route.name !== 'AccessDenied' && this.$route.name !== 'NotFound';
    },
    showFooter() {
      return ['Login', 'AccessDenied', 'NotFound'].includes(this.$route.name) || !!this.$route.meta.b2b || !!this.$route.meta.dms;
    },
    bodyClass() {
      if (this.$route.name === 'Login' || this.$route.meta.b2b || this.$route.meta.dms) return null;
      if (this.$route.name === 'AccessDenied' || this.$route.name === 'NotFound') return 'no-header';
      return 'no-footer';
    },
    formTemplate() {
      const bootstrapFormSettings = this.$store.getters
        .bootstrap?.settings?.uiConfig?.applicationForm;
      return bootstrapFormSettings?.template || '';
    },
    isLSZTemplateActive() {
      return this.formTemplate === APPLICATION_FORM.TEMPLATE.CITY_OF_ZURICH;
    },
    isViewSupportedByLSZ() {
      return ROUTES_SUPPORTED_BY_LSZ.includes(this.$route.name);
    },
    viewBackground() {
      switch (true) {
        case this.$route.name === 'B2BProjectTeaser':
        case this.isLSZTemplateActive && this.isViewSupportedByLSZ:
          return '#ffffff';
        default: return '#f2f5f7';
      }
    },
    poweredBy() {
      return this.$route.name === 'AccessDenied' || this.$route.name === 'NotFound';
    },
  },
  beforeCreate() {
    this.$freshdeskHelpWidget.hide();
  },
};
</script>

<style lang="scss" scoped>
.unprotected-view {
  padding: 0 $unprotected-view-padding;

  @media only screen and (max-width: 576px) {
    padding: 0 $unprotected-view-padding-small;
  }
}

.unprotected-view-content {
  width: 100%;
  max-width: $unprotected-view-max-width;
  min-height: calc(100vh - #{$login-header-height} - #{$login-footer-height});
  margin: 0 auto;
  color: $color-text-primary;

  .unprotected-view-router {
    min-height: calc(100vh - #{$login-header-height} - #{$login-footer-height});
  }

  &.no-header {
    min-height: calc(100vh - #{$login-footer-height});

    .unprotected-view-router {
      min-height: calc(100vh - #{$login-footer-height});
    }
  }

  &.no-footer {
    min-height: calc(100vh - #{$login-header-height});

    .unprotected-view-router {
      min-height: calc(100vh - #{$login-header-height});
    }
  }

  // all public views except Login | 403 | 404 (those with page-wrapper class) add vertical padding
  & > div:not(.page-wrapper) {
    padding: $unprotected-view-padding 0;

    @media only screen and (max-width: 576px) {
      padding: $unprotected-view-padding-small 0;
    }
  }

  .unprotected-view-router {
    width: 100%;

    // page-wrapper is for views with content (left) + image (right)
    &.page-wrapper ::v-deep {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .page-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 35%;
        margin-right: 23px;

        .page-title {
          color: $color-back-basic;
          font-size: 128px;
          font-weight: $font-weight-bold;
          line-height: 128px;
        }

        .page-description {
          padding-top: 16px;
          color: $color-text-secondary;
          font-size: 32px;
          font-weight: $font-weight-bold;
        }

        .page-info {
          padding-top: 16px;
          color: $color-text-secondary;
          font-size: 16px;
        }

        .page-button-left {
          margin: 32px 10px 0 0;
        }

        .page-button-right {
          margin: 32px 0 0 10px;
        }
      }

      .page-image,
      .page-slider {
        width: 65%;
        margin-left: 23px;
      }

      @media only screen and (max-width: 768px) {
        display: block;
        text-align: center;

        .page-content {
          width: 100%;
          max-width: 500px;
          align-items: center;
          margin: 0 auto;
          padding-bottom: 60px;
        }

        .page-image,
        .page-slider {
          width: 100%;
          max-width: 500px;
          margin: 0 auto;
        }
      }

      @media only screen and (max-width: 360px) {
        .page-content {
          .page-title {
            font-size: 96px;
          }

          .page-description {
            font-size: 24px;
          }

          .page-info {
            font-size: 14px;
          }
        }
      }
    }

    ::v-deep {
      .unprotected-view-form {
        width: 100%;
      }

      .unprotected-view-max-760 {
        max-width: 760px;
      }

      .unprotected-view-title {
        max-width: 760px;
        padding-bottom: 20px;
        font-size: 20px;
        font-weight: 700;

        i {
          padding-right: 10px;
        }
      }

      .unprotected-view-subtitle {
        max-width: 760px;
        padding-bottom: 20px;
        font-size: 16px;
        font-weight: $font-weight-bold;
      }

      .unprotected-view-description {
        max-width: 760px;
        padding-bottom: 20px;
        color: $color-text-secondary;
        font-size: 16px;
      }

      .mdt-pdf-preview {
        @media only screen and (max-width: 840px) {
          .modal-header {
            height: 120px;
          }

          .modal-body {
            width: 100vw;
            padding: 0 10px;
          }
        }
      }
    }
  }
}
</style>
