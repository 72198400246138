import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
export default {
  getProjectTeaser(state, projectSlug) {
    return api.get(`/api/v1/b2b/objects/${projectSlug}/project-teaser`, { params: { format: 'json' } }).then((response) => response.data);
  },
  getRegisterData({ commit }, projectSlug) {
    return api.get(`api/v2/b2b/register/${projectSlug}`).then((response) => {
      commit('setGenderChoices', response.data);
      return response.data;
    });
  },
  register(state, { projectSlug, params }) {
    return api.post(`api/v2/b2b/register/${projectSlug}`, params).then((response) => response.data);
  },
  resendVerificationMail(state, { projectSlug, params }) {
    return api.post(`api/v2/b2b/send-email-verification/${projectSlug}`, params).then((response) => response.data);
  },
  verifyEmail(state, { projectSlug, userSlug, token }) {
    return api.post(`api/v2/b2b/verify-email/${projectSlug}/${userSlug}/${token}`).then((response) => response.data);
  },
  login(state, { projectSlug, params }) {
    return api.post(`api/v2/b2b/login/${projectSlug}`, params).then((response) => response.data);
  },
  resendPhoneNumberAuthCode(state, { projectSlug, userSlug }) {
    return api.post(`api/v2/b2b/send-phone-verification/${projectSlug}/${userSlug}`).then((response) => response.data);
  },
  sendMailForChangePhoneNumber(state, { projectSlug, userSlug }) {
    return api.post(`api/v2/b2b/reset-phone-number-request/${projectSlug}/${userSlug}`).then((response) => response.data);
  },
  changePhoneNumber(state, { projectSlug, userSlug, params }) {
    return api.post(`api/v2/b2b/reset-phone-number/${projectSlug}/${userSlug}`, params).then((response) => response.data);
  },
  phoneNumberAuth({ commit }, { projectSlug, userSlug, params }) {
    return api.post(`api/v2/b2b/verify-phone/${projectSlug}/${userSlug}`, params).then((response) => {
      commit('auth/login', {
        ...response.data,
        userType: 'b2b',
      }, { root: true });
      return response.data;
    });
  },
  sendMailForResetPassword(state, { projectSlug, params }) {
    return api.post(`api/v2/b2b/reset-password-request/${projectSlug}`, params).then((response) => response.data);
  },
  resetPassword(state, {
    projectSlug,
    userSlug,
    token,
    params,
  }) {
    return api.post(`api/v2/b2b/reset-password/${projectSlug}/${userSlug}/${token}`, params).then((response) => response.data);
  },
  getProjectNdaData(state, projectSlug) {
    return api.get(`/api/v1/investors/sign-nda/${projectSlug}`).then((response) => response.data);
  },
  signProjectNda(state, projectSlug) {
    return api.post(`api/v1/investors/sign-nda/${projectSlug}`).then((response) => response.data);
  },
  getProjectMemorandum(state, projectSlug) {
    return api.get(`/api/v1/b2b/objects/${projectSlug}/memorandum-page`).then((response) => response.data);
  },
  getProjectMemorandumPitch(state, projectSlug) {
    return api.get(`/api/v1/b2b/objects/${projectSlug}/memorandum-page-pitch`).then((response) => response.data);
  },
  setNboInterest(state, { payload, applyId }) {
    return api.patch(`/api/v1/investors/${applyId}/update-apply`, payload).then((response) => response.data);
  },
  logout({ commit }) {
    return api.post('api/v2/b2b/logout')
      .catch(() => {})
      .finally(() => {
        commit('auth/logout', null, { root: true });
      });
  },
  getInvestorOwnerCreate() {
    return api.get('/api/v1/teams/b2b/proprietor/create').then((response) => response.data);
  },
  postInvestorOwnerCreate(state, payload) {
    return api.post('/api/v1/teams/b2b/proprietor/create', payload).then((response) => response.data);
  },
  getInvestorOwnerEdit(state, id) {
    return api.get(`/api/v1/teams/${id}/b2b/proprietor/edit`).then((response) => response.data);
  },
  editInvestorOwner(state, { id, payload }) {
    return api.patch(`/api/v1/teams/${id}/b2b/proprietor/edit`, payload).then((response) => response.data);
  },
  deleteInvestorOwner(state, targets) {
    return api.post('/api/v1/settings/owners-investors', { bulkAction: 'delete', targets }).then((response) => response.data);
  },
  updateNda(state, { id, payload }) {
    return api.post(`api/v1/b2b/objects/${id}/update-nda-template`, payload).then((response) => response.data);
  },
  investorUnsubscribe(state, { projectSlug, projectToken }) {
    return api.get(`api/v1/investors/action/${projectSlug}/${projectToken}/investor-opt-out`).then((response) => response.data);
  },
};
