import { render, staticRenderFns } from "./MdtCreateButton.vue?vue&type=template&id=ad895db0&scoped=true"
import script from "./MdtCreateButton.vue?vue&type=script&lang=js"
export * from "./MdtCreateButton.vue?vue&type=script&lang=js"
import style0 from "./MdtCreateButton.vue?vue&type=style&index=0&id=ad895db0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad895db0",
  null
  
)

export default component.exports