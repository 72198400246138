<template>
  <div
    class="unprotected-view-footer flex-center-v"
    :class="{ 'flex-right': poweredBy }">
    <div
      v-if="poweredBy"
      class="footer-info-powered-by">
      Powered by
      <span class="font-bold">
        melon<span v-if="projectType">.<span class="color-theme">{{ projectTheme }}</span></span>
      </span>
    </div>
    <template v-else>
      <div
        class="footer-info-left"
        :class="{ 'b2b-footer': isTeaserMemorandumPage }">
        <div v-html="sanitizedContent(left || publicPage.footerLeft)" />
      </div>
      <div class="footer-info-right">
        <div v-html="sanitizedContent(right || publicPage.footerRight)" />
        <div
          class="privacy-policy"
          @click="isModalOpen = true">
          {{ 'admin_privacy_policy' | translate }}
        </div>
        <MdtModalPrivacyPolicy
          v-if="isModalOpen"
          :datenschutz="datenschutz"
          @close="isModalOpen = false" />
      </div>
    </template>
  </div>
</template>

<script>
import MdtModalPrivacyPolicy from '@/components/shared/modals/MdtModalPrivacyPolicy.vue';

export default {
  name: 'UnprotectedViewFooter',
  components: {
    MdtModalPrivacyPolicy,
  },
  props: {
    left: {
      type: String,
      default: '',
    },
    right: {
      type: String,
      default: '',
    },
    datenschutz: {
      type: String,
      default: '',
    },
    poweredBy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  computed: {
    publicPage() {
      return this.$store.state.publicPage;
    },
    projectType() {
      return this.$store.getters.projectType;
    },
    projectTheme() {
      return this.$store.getters.projectTheme;
    },
    isTeaserMemorandumPage() {
      const teaser = this.$route.name === 'B2BProjectTeaser';
      const memorandum = this.$route.name === 'B2BProjectMemorandum';
      return teaser || memorandum;
    },
  },
};
</script>

<style lang="scss" scoped>
.unprotected-view-footer {
  width: 100%;
  max-width: $unprotected-view-max-width;
  height: $login-footer-height;
  justify-content: space-between;
  margin: 0 auto;
  padding: 24px 0;
  color: $color-text-secondary;
  font-size: 16px;
  border-top: 1px solid $color-back-basic;

  &.flex-right {
    justify-content: flex-end;
  }

  .footer-info-left:not(.b2b-footer) {
    margin-right: 30px;

    ::v-deep p:first-child {
      color: $color-text-primary;
      font-size: 20px;
      font-weight: $font-weight-bold;
    }
  }

  ::v-deep a,
  .privacy-policy {
    color: $color-theme;
    cursor: pointer;
  }

  .privacy-policy {
    text-align: right;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;

    .footer-info-left {
      margin: 0 0 16px 0;
    }

    .privacy-policy {
      text-align: left;
    }
  }

  @media only screen and (max-width: 360px) {
    font-size: 14px;

    .footer-info-left {
      ::v-deep p:first-child {
        font-size: 16px;
      }
    }
  }
}
</style>
