<template>
  <div
    class="mdt-number-incrementer"
    :class="{ 'has-error': hasError, disabled }">
    <div
      v-if="label"
      class="label">
      {{ label }}
      <i
        v-if="required"
        class="fa-asterisk field-required" />
      <i
        v-if="tooltip"
        v-tooltip="tooltip"
        class="far fa-info-circle info-icon" />
    </div>
    <div
      class="wrapper flex-center-v"
      :class="{ readonly }">
      <div
        id="decrement"
        class="icon flex-center pointer no-userselect"
        :class="{ disabled: decrementDisabled }"
        @click="decrement">
        <i class="fas fa-minus" />
      </div>
      <input
        type="number"
        class="input"
        :class="{ 'has-error': hasError, disabled }"
        :value="value"
        :readonly="readonly"
        @input="onInput($event.target.value)" />
      <div
        id="increment"
        class="icon flex-center pointer no-userselect"
        :class="{ disabled: incrementDisabled }"
        @click="increment">
        <i class="fas fa-plus" />
      </div>
      <i
        v-if="showClearButton"
        class="fas fa-eraser clear-button"
        @click="clearValue" />
    </div>
    <div
      v-if="clientErrors.length || serverErrors.length"
      class="input-errors">
      <span class="client-errors">
        {{ clientErrors.join('\n') }}
        {{ clientErrors.length && serverErrors.length ? '\n' : '' }}
      </span>
      <span class="server-errors">
        {{ serverErrors.join('\n') }}
      </span>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { helpers } from '@/utility';

export default {
  name: 'MdtNumberIncrementer',
  mixins: [validationMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: Number,
      required: false,
    },
    label: {
      type: String,
      default: '',
    },
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: Number.MIN_SAFE_INTEGER,
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disableClear: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  data() {
    const { translate } = this.$options.filters;
    return {
      helpers,
      serverErrors: this.errors || [],
      inputValue: this.value,
      msg: {
        minValidation: translate('admin_field_greater_or_equal_then_minimum')
          .replace('[p]', this.label || translate('general_number'))
          .replace('[m]', this.min),
        maxValidation: translate('admin_field_smaller_or_equal_then_maximum')
          .replace('[p]', this.label || translate('general_number'))
          .replace('[m]', this.max),
        requiredField: translate('general_field_is_required'),
      },
    };
  },
  computed: {
    clientErrors() {
      const errors = [];
      const isDirty = this.$v.inputValue.$dirty;
      if (!this.$v.inputValue.required && isDirty) {
        errors.push(this.msg.requiredField);
      }
      if (!this.$v.inputValue.minValue) {
        errors.push(this.msg.minValidation);
      }
      if (!this.$v.inputValue.maxValue) {
        errors.push(this.msg.maxValidation);
      }
      return errors;
    },
    decrementDisabled() {
      return this.disabled || (
        this.inputValue !== undefined
          && this.inputValue !== null && (this.inputValue - this.step) < this.min);
    },
    showClearButton() {
      return !this.required && !this.disableClear && !this.disabled;
    },
    incrementDisabled() {
      return this.disabled || (
        this.inputValue !== undefined
          && this.inputValue !== null && (this.inputValue + this.step) > this.max);
    },
    hasError() {
      return this.$v.inputValue.$error || this.serverErrors.length;
    },
  },
  watch: {
    value() {
      this.inputValue = this.value;
    },
    errors(value) {
      this.serverErrors = value;
    },
  },
  validations() {
    const numberValidation = {
      minValue: false,
      maxValue: false,
    };
    numberValidation.minValue = minValue(this.min);
    numberValidation.maxValue = maxValue(this.max);
    return {
      inputValue: {
        required: this.required ? required : false,
        ...numberValidation,
      },
    };
  },
  methods: {
    setTouched() {
      this.$v.inputValue.$touch();
    },
    isValid() {
      this.setTouched();
      const isValid = !this.$v.inputValue.$error && !this.serverErrors.length;
      return isValid;
    },
    decrement() {
      // Return if disabled or minimum reached if decrement
      if (this.decrementDisabled) return;

      let { value } = this;

      // If value is valid integer
      if (Number.isInteger(value)) {
        if ((value - this.step) < this.min) value = null;
        else value -= this.step;
      } else { // If value is not integer revert to minimum value or 0
        value = this.min !== Number.MIN_SAFE_INTEGER ? this.min : 0;
      }
      // If value changed -> emit new value and reset server errors
      if (value !== this.value) {
        this.$emit('input', value);

        // emit mdtDataChanged event so changes could be detected
        this.$emit('mdtDataChanged');

        this.serverErrors = [];
      }
    },
    increment() {
      // Return if disabled or maximum reached if increment
      if (this.incrementDisabled) return;

      let { value } = this;
      // If value is valid integer increment value
      if (Number.isInteger(value)) {
        if ((value + this.step) > this.max) value = null;
        else value += this.step;
      } else {
        // If value is not integer revert to minimum or 0
        value = this.min !== Number.MIN_SAFE_INTEGER ? this.min : 0;
      }
      // If value changed -> emit new value and reset server errors
      if (value !== this.value) {
        this.$emit('input', value);

        // emit mdtDataChanged event so changes could be detected
        this.$emit('mdtDataChanged');

        this.serverErrors = [];
      }
    },
    clearValue() {
      // emit null value and reset server errors
      this.$emit('input', null);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');

      this.serverErrors = [];
    },
    onInput(val) {
      const value = Number(val) || null;
      this.isValid();
      this.inputValue = value;
      this.$emit('input', value);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');

      // Reset server errors
      this.serverErrors = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-number-incrementer {
  &.has-error {
    color: $color-danger;

    .icon i {
      color: $color-danger;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;

    ::v-deep .icon {
      color: $color-text-secondary !important;
      opacity: 1 !important;
    }
  }

  .label {
    margin-bottom: 12px;
    color: $color-text-secondary;
    font-size: 14px;
    line-height: 14px;

    .info-icon {
      margin-left: 8px;
      color: $color-text-secondary;

      &:hover {
        color: $color-text-primary;
      }
    }

    .field-required {
      color: $color-danger;
      margin-left: 2px;
    }
  }

  .wrapper {
    display: inline-flex;

    &.readonly {
      cursor: not-allowed;

      .no-userselect {
        pointer-events: none;
      }
    }
  }

  .icon {
    width: 40px;
    height: 40px;
    line-height: 32px;
    background-color: $color-back-basic;
    border-radius: 4px;

    &:hover {
      opacity: 0.75;
    }

    &.disabled {
      color: rgba(72, 72, 72, 0.6);
      background-color: rgba(226, 226, 226, 0.5);
      opacity: 0.5;
      cursor: not-allowed;
    }

    i {
      color: $color-text-secondary;
      font-size: 0.75rem;
    }
  }

  .clear-button {
    margin-left: 8px;
    color: $color-text-secondary;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      opacity: 0.75;
    }
  }

  input {
    width: 80px!important;
    height: 40px;
    line-height: 32px;
    margin: 0 8px;
    border: 1px solid $border-color;
    border-radius: 4px;
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }

    &:hover,
    &:focus {
      outline: 0;
      border-color: $color-text-secondary;
    }

    &.has-error {
      border-color: $color-danger;
    }
  }

  .input-errors {
    padding-top: 4px;
    font-size: 12px;
    font-weight: $font-weight-normal;
    white-space: pre-line;
  }

  .required-asterisk {
    color: $color-danger;
  }
}
</style>
