<template>
  <component
    :is="tooltip.length ? 'stzh-tooltip' : 'div'"
    :content="tooltip"
    :size="tooltipSize"
    :placement="tooltipPlacement">
    <stzh-button
      :disabled="disabled"
      :variant="variant"
      :label="value"
      :icon="icon"
      :size="size"
      :icon-only="iconOnly"
      :no-padding-left="noPaddingLeft"
      @stzhClick="$emit('stzhClick')" />
  </component>
</template>

<script>
export default {
  name: 'StzhButtonComponent',
  props: {
    value: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      default: '',
    },
    tooltipSize: {
      type: String,
      default: 'default',
    },
    tooltipPlacement: {
      type: String,
      default: 'top-start',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'default',
    },
    icon: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'default',
    },
    noPaddingLeft: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
