import { render, staticRenderFns } from "./MdtWizardModal.vue?vue&type=template&id=312bc95c&scoped=true"
import script from "./MdtWizardModal.vue?vue&type=script&lang=js"
export * from "./MdtWizardModal.vue?vue&type=script&lang=js"
import style0 from "./MdtWizardModal.vue?vue&type=style&index=0&id=312bc95c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "312bc95c",
  null
  
)

export default component.exports